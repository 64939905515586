<script lang="ts" setup>
const props = defineProps<{
	id: string;
	height?: number;
}>();
const targetRef = ref(null);
const isVisible = ref(false);
const height = computed(() => `${props.height || "200"}px`);

const { stop } = useIntersectionObserver(
	targetRef,
	([{ isIntersecting }]) => {
		if (isIntersecting) {
			isVisible.value = isIntersecting;
			stop();
		}
	},
	{ rootMargin: "-50px", threshold: 0 }
);

onBeforeUnmount(() => stop());
</script>
<template>
	<div :id="id" ref="targetRef" :class="{ 'lazy-skeleton': !isVisible }">
		<slot v-if="isVisible" />

		<AAnimationSkeleton v-else>
			<ASkeleton width="100%" :height="height" />
		</AAnimationSkeleton>
	</div>
</template>
